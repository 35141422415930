<template>
    <v-container>
        <v-content class="pa-8">
            <v-card>
                <w-whiteboard></w-whiteboard>
            </v-card>
        </v-content>
    </v-container>

</template>

<script>
    export default {
        name: "Logs",
        data: () => ({
            title: "Logs"
        }),
    }
</script>

<style scoped>

</style>